<template>
  <Transition name="slide-fade">
    <div v-show="isShow"
         class="filter-wrap">
      <el-icon v-if="isMobile"
               :size="24"
               @click="hide"
               class="close-icon">
        <CloseBold/>
      </el-icon>
      <h3 v-if="isMobile"
          class="title">{{ $t('filter.title') }}</h3>
      <div v-if="isMobile"
           class="filter-group-wrap">
        <!-- 坪數 -->
        <div class="w-[150px]">
          <div class="my-[12px] text-[18px] font-semibold text-osn-dark-grey-00">{{ $t('filter.room_size') }}</div>
          <DropdownSelectFilter class="ml-[20px]"
                                :range-config="roomSizeFilterConfig.rangeConfig"
                                :option-list="roomSizeFilterConfig.optionList"
                                :filter-name="roomSizeFilterConfig.filterName"
                                :initial-range="roomSizeFilterConfig.initialRange"
                                @update:selected-item="handleRoomSizeChange"></DropdownSelectFilter>
        </div>

        <!-- 是否靠近捷運站 -->
        <div class="my-[12px] text-[18px] font-semibold text-osn-dark-grey-00">{{ $t('filter.needs_to_be_close_to_mrt_title') }}</div>
        <div class="filter-group-content-item"
             @click="handleCloseMrt">
          <svg-icon v-if="needToBeCloseToMrt"
                    class="mr-[6px] bg-osn-yellow-00 cursor-pointer"
                    name="check_box"
                    size="18"></svg-icon>
          <div v-else
               class="check-box"></div>
          <span>{{ $t('filter.needs_to_be_close_to_mrt') }}</span>
        </div>
        <FilterGroup v-if="categoryTypeList?.length>0"
                     :title="$t('filter.type_title')"
                     :list="categoryTypeList"
                     :handle-click="handleType"/>
        <FilterGroup class="mt-[40px]"
                     :title="$t('filter.equipment_title')"
                     :list="equipmentList"
                     :handle-click="handleEquipmentList"/>
        <FilterGroup class="mt-[40px]"
                     :title="$t('filter.service_title')"
                     :list="serviceList"
                     :handle-click="handleService"/>
        <FilterGroup v-if="categoryTagList?.length>0"
                     :title="$t('filter.tag_title')"
                     :list="categoryTagList"
                     :handle-click="handleTag"/>
      </div>
      <div v-else
           class="filter-group-wrap">
        <!-- 坪數 -->
        <div class="w-[150px]">
          <div class="my-[12px] text-[18px] font-semibold text-osn-dark-grey-00">{{ $t('filter.room_size') }}</div>
          <DropdownSelectFilter class="ml-[20px]"
                                :range-config="roomSizeFilterConfig.rangeConfig"
                                :option-list="roomSizeFilterConfig.optionList"
                                :filter-name="roomSizeFilterConfig.filterName"
                                :initial-range="roomSizeFilterConfig.initialRange"
                                @update:selected-item="handleRoomSizeChange"></DropdownSelectFilter>
        </div>

        <!-- 是否靠近捷運站 -->
        <div class="my-[12px] text-[18px] font-semibold text-osn-dark-grey-00">{{ $t('filter.needs_to_be_close_to_mrt_title') }}</div>
        <div class="filter-group-content-item"
             @click="handleCloseMrt">
          <svg-icon v-if="needToBeCloseToMrt"
                    class="mr-[6px] bg-osn-yellow-00 cursor-pointer"
                    name="check_box"
                    size="18"></svg-icon>
          <div v-else
               class="check-box"></div>
          <span>{{ $t('filter.needs_to_be_close_to_mrt') }}</span>
        </div>

        <PCFilterBarDropdown v-if="categoryTypeList?.length>0"
                             :title="$t('filter.type_title')"
                             :list="categoryTypeList"
                             :handle-click="handleType"/>

        <PCFilterBarDropdown class="mt-[30px]"
                             :title="$t('filter.equipment_title')"
                             :list="equipmentList"
                             :handle-click="handleEquipmentList"/>
        <PCFilterBarDropdown class="mt-[30px]"
                             :title="$t('filter.service_title')"
                             :list="serviceList"
                             :handle-click="handleService"/>
        <PCFilterBarDropdown v-if="categoryTagList?.length>0"
                             :title="$t('filter.tag_title')"
                             :list="categoryTagList"
                             :handle-click="handleTag"/>
      </div>
      <div class="filter-btn-group">
        <div class="clear"
             @click="handleClear">{{ $t('filter.clear') }}
        </div>
        <div class="search"
             @click="handelSearch">{{ $t('filter.search') }}
        </div>
      </div>
    </div>
  </Transition>
  <div v-if="isShow"
       class="fixed top-0 bottom-0 left-0 right-0 outline-none"
       @click="isShow=!isShow">
  </div>
</template>
<script setup>
import {ref, defineProps, computed, onMounted, watchEffect} from "vue";
import {CloseBold} from "@element-plus/icons-vue";
import {useI18n} from 'vue-i18n'
import FilterGroup from "@/components/MenuNav/FilterGroup.vue";
import PCFilterBarDropdown from "@/components/MenuNav/PCFilterBarDropdown.vue";
import {useFilterStore} from "@/stores/filter";
import {useSettingStore} from "@/stores/setting.js";
import {useCategoryStore} from "@/stores/category.js";
import {useSearchQueryStore} from "@/stores/searchQuery.js";
import {useSearchWithStore} from "@/composables/useSearchWithStore";
import DropdownSelectFilter from "@/components/SearchResult/DropdownSelectFilter.vue";
import {roomSizeRange, roomSizeList} from "~/config/common.js";

const {t} = useI18n();
const {handleSearchWithStore} = useSearchWithStore()
const settingStore = useSettingStore()
const filterStore = useFilterStore();
const categoryStore = useCategoryStore()
const searchQueryStore = useSearchQueryStore()

const tagCategoryId = import.meta.env.VITE_APP_BASE_TAG_CATEGORY_ID
const typeCategoryId = import.meta.env.VITE_APP_BASE_TYPE_CATEGORY_ID

const isMobile = computed(() => settingStore.isMobile);
const isShow = ref(false)

const props = defineProps({
  getLocationList: {
    type: Function
  }
})

defineExpose({
  show,
  hide
});

// category id=4獨立風格
const categoryTagList = ref(computed(() => filterStore.getCategoryTagList));

const categoryTypeList = ref(computed(() => filterStore.getCategoryTypeList));

// 有相依性的風格
// const tagList = computed(() => filterStore.getTagList);
const equipmentList = computed(() => filterStore.getEquipmentList);
const serviceList = computed(() => filterStore.getServiceList);

const needToBeCloseToMrt = computed(() => searchQueryStore.getNeedsToBeCloseToMrt)


const roomSizeFilterConfig = {
  filterName: t('filter.room_size'),
  optionList: roomSizeList,
  rangeConfig: roomSizeRange,
  initialRange: searchQueryStore.getPeopleCapRange,
};
const handleRoomSizeChange = (newValue) => {
  searchQueryStore.setSelectedRoomSizeRange(roomSizeFilterConfig.rangeConfig[newValue] || {});
};


// 重新整理時，要把已經選擇的選項打勾
watchEffect(() => {

  try {
    categoryTagList.value.forEach((item) => {
      item.selected = searchQueryStore.getSearchCategoryIds[tagCategoryId]?.includes(item.id);
    });

    categoryTypeList.value.forEach((item) => {
      item.selected = searchQueryStore.getSearchCategoryIds[typeCategoryId]?.includes(item.id);
    });

    equipmentList.value.forEach((item) => {
      item.selected = searchQueryStore.getEquipmentIds.includes(item.equipment_id);
    });

    serviceList.value.forEach((item) => {
      item.selected = searchQueryStore.getServiceIds.includes(item.service_id);
    });
  } catch (e) {
    console.log(e)
  }


})

function show() {
  isShow.value = true
}

function hide() {
  isShow.value = false
}

const handleTag = (item) => {
  item.selected = !item.selected
}

const handleType = (item) => {
  item.selected = !item.selected
}

const handleEquipmentList = (item) => {
  item.selected = !item.selected
}

const handleService = (item) => {
  item.selected = !item.selected
}

const handleClear = () => {
  filterStore.clearFilterSelected();
}

const handleCloseMrt = () => {
  // 透過 store 方法來更新值，computed 自動同步更新
  searchQueryStore.setNeedsToBeCloseToMrt(!needToBeCloseToMrt.value)
}

const handelSearch = () => {
  searchQueryStore.initGeoSearch()


  // todo 搜尋的時候才把filter的值帶入，沒有按下搜尋前畫面上會有已經有打勾的，但是都沒搜尋前都不會存過去，這樣不知道會不會造成誤會

  // 新的首頁搜尋用
  const categoryTagIDList = filterStore.getSelectedCategoryTag
  const categoryTypeIDList = filterStore.getSelectedCategoryType
  const selectedEquipmentList = filterStore.getSelectedEquipment
  const selectedServiceList = filterStore.getSelectedService

  searchQueryStore.setEquipmentIds(selectedEquipmentList)
  searchQueryStore.setServiceIds(selectedServiceList)

  searchQueryStore.setSelectedCategoryObject({
    [tagCategoryId]: categoryTagIDList,
    [typeCategoryId]: categoryTypeIDList
  });
  handleSearchWithStore()

  // props.getLocationList();

  hide();
}

</script>
<style scoped
       lang="scss">
.filter-wrap {
  @apply px-[20px] bg-white z-50
  md:pt-0 md:absolute md:top-[60px] md:bottom-auto md:left-0 md:right-auto md:rounded-[20px] md:shadow-xl
  pt-[60px] fixed top-0 bottom-0 left-0 right-0;
  .close-icon {
    @apply absolute top-[60px] right-[20px] text-center z-50 cursor-pointer;
  }

  .title {
    @apply text-center text-[20px] text-osn-dark-grey-00;
  }

  .filter-group-wrap {
    @apply overflow-y-scroll
    md:mt-[20px] md:mb-[90px] md:h-[410px]
    mt-[22px] h-[calc(100%-40px-50px-50px)];

    .filter-group-content-item {
      @apply mx-[20px] my-[20px] cursor-pointer flex items-center;
      .check-box, img {
        @apply mr-[6px] w-[18px] h-[18px] cursor-pointer;
      }

      img {
        @apply bg-osn-yellow-00;
      }

      .check-box {
        @apply border-2 border-osn-dark-grey-00;
      }
      span{
        @apply text-[16px] text-osn-dark-grey-00;
      }
    }
  }

  /* 捲軸底色 */
  .filter-group-wrap::-webkit-scrollbar-track {
    @apply bg-osn-white-00;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  /* 捲軸寬度 */
  .filter-group-wrap::-webkit-scrollbar {
    width: 6px;
    background-color: black;
  }

  /* 捲軸本體顏色 */
  .filter-group-wrap::-webkit-scrollbar-thumb {
    @apply bg-osn-yellow-00;
  }


  .filter-btn-group {
    @apply absolute right-0 bottom-[20px] left-0 flex justify-center mt-[40px] text-[16px] text-osn-black-00;

    .clear {
      @apply w-[30%] h-[50px] rounded-[50px] text-center leading-[50px]
      border border-osn-black-00 cursor-pointer bg-osn-white-00;
    }

    .search {
      @apply w-[65%] h-[50px] rounded-[50px] text-center leading-[50px]
      bg-osn-yellow-00 cursor-pointer ml-[12px];
    }
  }
}


/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}


.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
}
</style>
